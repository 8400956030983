import React, { useEffect, useState } from 'react';
import './scss/style.scss';
import { RiFacebookLine } from 'react-icons/ri';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import delfiTicketsImage from '../../assets/images/footer/delfi-ulaznice-white-logo.png';
import edenBooksImage from '../../assets/images/footer/eden-logo-blue.svg';
// import googlePlayStoreImage from '../../assets/images/footer/google-play-badge.png';
import huaweiAppStoreImage from '../../assets/images/footer/huawei.png';
import hotSpot23Image from '../../assets/images/footer/hotSpot23.webp';
import appleStoreImage from '../../assets/images/footer/apple-store.svg';
import delfiLogoImage from '../../assets/images/footer/delfi-logo-blue.svg';
import bukmarkerKafeterijaLogoImage from '../../assets/images/footer/cafeteria.svg';
import bukmarkerMagazinImage from '../../assets/images/footer/bookmaker.svg';
import delfiPremiumImage from '../../assets/images/footer/delfi-premium-logo.svg';
import masterCardImage from '../../assets/images/footer/mastercard-logo.svg';
import maestroImage from '../../assets/images/footer/mastercard-maestro.svg';
import visaImage from '../../assets/images/footer/visa-payment-card.svg';
import americanCardImage from '../../assets/images/footer/american_express-logo.svg';
import dinaCardImage from '../../assets/images/footer/dina-card-logo.svg';
import bancaIntesaImage from '../../assets/images/footer/footer-icon-bancaintesa.svg';
import masterCardSecureImage from '../../assets/images/footer/master-card-secure-code.svg';
import verifiedVisaImage from '../../assets/images/footer/verified-by-visa.svg';
import etrustImage from '../../assets/images/footer/footer-icon-etrustmark.svg';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Button from '../../components/button';
import BackToTopBtn from '../../components/back-to-top';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { composeValidators, email } from '../../components/forms/validators';
import Error from '../../components/forms/Error';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../../config';
import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { subscribeNewsLetterAction } from '../../../app/store/newsletter/actions';
import { createCookieConsentAction } from '../../../app/store/cookie-consent/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Spinner from '../../components/spinner/Spinner';
import { openWindowPopup, recognizeSearchCrawler } from '../../../utilites';
import twitterIconImage from '../../assets/images/logoX.svg';
import { TransitionSteps } from '../../../constants/TransitionSteps';
import downArrayImage from '../../assets/images/down-arrow.svg';
import upArrayImage from '../../assets/images/arrow-up-black.svg';
import NewsletterCookieModal from '../../components/newsletter-cookie-modal';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Footer: React.FC<Props> = ({
  subscribeNewsLetterAction,
  createCookieConsentAction,
  ui,
  newsLetterData,
  cookieConsentData
}: Props) => {
  const navigate = useNavigate();
  const refRecaptcha = React.useRef(null);
  const [newsLetterMessage, setNewsLetterMessage] = useState(
    newsLetterData && newsLetterData.message ? newsLetterData.message : ''
  );

  const [checkBoxValues, setCheckBoxValue] = useState({
    necessary: true,
    analytics: true,
    marketing: true
  });

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setCheckBoxValue((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleOnClickSetCookieConsent = (isCookieAll = true) => {
    if (!isCookieAll) {
      const updatedCookieConsent = {
        ...checkBoxValues,
        analytics: false,
        marketing: false
      };
      return createCookieConsentAction({ cookieConsent: updatedCookieConsent });
    }

    return createCookieConsentAction({ cookieConsent: checkBoxValues });
  };

  const [showMoreCookieText, setShowMoreCookieText] = useState(false);

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const handleToggle = () => {
    setShowMoreCookieText(!showMoreCookieText);
  };

  const handleOnSubmitSearchBookstores = (values: any) => {
    if (values.searchBookstoreInput) {
      return navigate(`/knjizare?name=${values.searchBookstoreInput}`);
    }

    return navigate('/knjizare');
  };

  const handleOnSubmitSubscribeOnNewsLetter = async (values: any) => {
    if (values.email.length > 4) {
      //@ts-ignore
      values.recaptcha = await refRecaptcha.current.executeAsync();
      subscribeNewsLetterAction(values);
    }
  };

  useEffect(() => {
    setNewsLetterMessage(newsLetterData.message);
  }, [newsLetterData]);

  return (
    <>
      <NewsletterCookieModal />
      <footer className="text-center text-lg-start mt-5">
        <section className="background-delfi color-white">
          <div className="container">
            <div className="row me-0 gx-2 ms-0 mt-4 mb-4 p-4 justify-content-center text-center">
              <div className="col-xl-5 col-md-9 me-xl-5 me-0">
                <div>
                  <span>PRONAĐI NAJBLIŽU KNJIŽARU</span>
                </div>
                <div className="col-md-12 mt-3">
                  <Form
                    onSubmit={handleOnSubmitSearchBookstores}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group input-group-lg">
                          <Field name="searchBookstoreInput">
                            {({ input, meta }) => (
                              <input
                                {...input}
                                type="text"
                                className="form-control rounded-0 jost-regular shadow-none"
                                placeholder="Unesite grad, ili naziv knjižare..."
                              />
                            )}
                          </Field>
                          <Button
                            classes="jost-extra-bold btn btn-search background-orange rounded-0 text-14 font-color-default shadow-none"
                            type="submit"
                          >
                            PRETRAGA
                          </Button>
                        </div>
                        <Field name="searchBookstoreInput">
                          {({ meta: { active, dirty, error, touched } }) => (
                            <Error
                              active={active}
                              dirty={dirty}
                              error={error}
                              touched={touched}
                            />
                          )}
                        </Field>
                      </form>
                    )}
                  ></Form>
                </div>
              </div>
              <div className="col-xl-5 col-md-9 mt-3 mt-xl-0 ms-xl-5 ms-0">
                <div>
                  <span>PRIJAVI SE NA NEWSLETTER LISTU</span>
                </div>
                <div className="col-md-12 mt-3">
                  <Form
                    onSubmit={handleOnSubmitSubscribeOnNewsLetter}
                    render={({
                      handleSubmit,
                      form,
                      submitting,
                      pristine,
                      values
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="input-group input-group-lg">
                          <Field
                            name="email"
                            validate={composeValidators(email)}
                          >
                            {({ input, meta }) => (
                              <input
                                {...input}
                                type="text"
                                className="form-control rounded-0 jost-regular shadow-none"
                                placeholder="Vaša e-mail adresa..."
                                onChange={(event) => {
                                  input.onChange(event);
                                  setNewsLetterMessage('');
                                }}
                              />
                            )}
                          </Field>
                          {ui.loaders.includes(
                            MiddlewareEntities.NEWSLETTER
                          ) ? (
                            <Spinner color="white" />
                          ) : (
                            <Button
                              classes="jost-extra-bold btn btn-search background-orange rounded-0 text-14 font-color-default shadow-none"
                              type="submit"
                            >
                              PRIJAVI SE
                            </Button>
                          )}
                        </div>
                        <Field name="email">
                          {({ meta: { active, dirty, error, touched } }) => (
                            <Error
                              active={active}
                              dirty={dirty}
                              error={error}
                              touched={touched}
                            />
                          )}
                        </Field>
                        {newsLetterMessage &&
                        !newsLetterData.isForNewsLetterForModal ? (
                          <div className="mt-1 ml-1 text-14">
                            <span
                              className={`${
                                newsLetterData.status === 'success'
                                  ? 'alert-success'
                                  : 'alert-danger'
                              }`}
                            >
                              {newsLetterData.message}
                            </span>
                          </div>
                        ) : null}
                        <ReCAPTCHA
                          ref={refRecaptcha}
                          sitekey={Config.recaptchaPublicKey}
                          size="invisible"
                        />
                      </form>
                    )}
                  ></Form>
                </div>
              </div>
            </div>
          </div>
          <BackToTopBtn />
        </section>
        <section className="mt-4">
          <div className="container">
            <div className="row mt-4 justify-content-center text-center align-items-center">
              <div className="col-sm-12 col-lg-12 col-xl-6">
                <div className="p-2 bg-white min-height-footer-ticket-section">
                  <div className="row me-0 justify-content-center ms-0 align-items-center mb-2">
                    <div className="col-lg-10 col-xl-4 borderRightSection mt-2">
                      <a href="https://edenbooks.rs" target="_blank">
                        <img
                          width="88"
                          height="32"
                          src={edenBooksImage}
                          alt="eden knjige"
                        />
                      </a>
                      <MediaQuery query="(max-width: 1180px)">
                        <hr />
                      </MediaQuery>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-4 mt-2 ">
                      <a
                        href="https://appgallery.huawei.com/#/app/C103216117"
                        target="_blank"
                      >
                        <img
                          width="170px"
                          className="img-fluid"
                          src={huaweiAppStoreImage}
                          alt="google play store"
                        />
                      </a>
                    </div>
                    <div className="col-lg-4 col-sm-4 col-4 mt-2">
                      <a
                        href="https://apps.apple.com/us/app/id1520384325"
                        target="_blank"
                      >
                        <img
                          className="img-fluid"
                          src={appleStoreImage}
                          alt="apple store"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-4 mt-4 mt-xl-0">
                <Link
                  to="/ulaznice"
                  className="text-decoration-none font-color-default"
                >
                  <div className="p-3 bg-white min-height-footer-ticket-section cursor-pointer">
                    <div className="row me-0 justify-content-sm-center text-center ms-0 align-items-center">
                      <div className="col-lg-10 col-xl order-sm-0 mt-3 mt-xl-0 borderRightSection">
                        <img
                          width="68"
                          height="39"
                          src={delfiTicketsImage}
                          alt="delfi ulaznice"
                        />
                        <MediaQuery query="(max-width: 1180px)">
                          <hr />
                        </MediaQuery>
                      </div>
                      <div className="col-lg-8 col-xxl-9 mt-4 mt-xl-0">
                        <p className="text-xl-start text-14 line-height-16">
                          Kupite ulaznice za bilo koji kulturni događaj, brzo i
                          jednostavno
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-12 col-xl-2 mt-4 mt-xl-0">
                <Link
                  to="/vesti/vest/9126_delfi_knjizare_dobitnik_hotspot_ecommerce_awards_delfi-knjizare.html"
                  className="text-decoration-none"
                >
                  <img
                    width="232"
                    height="82"
                    src={hotSpot23Image}
                    alt="hotspot pobednik"
                  />
                </Link>
              </div>
              {/*<div className="col-xl-4 col-lg-12 mt-4 mt-xl-0">*/}
              {/*  <div className="p-sm-1 p-2 bg-white min-height-footer-ticket-section">*/}
              {/*    <div className="row me-0 justify-content-sm-center text-center ms-0 align-items-center">*/}
              {/*      <div className="col-lg-10 col-xl order-sm-0 borderRightSection mt-2 mt-xl-0">*/}
              {/*        <a href="https://radiolaguna.rs" target="_blank">*/}
              {/*          <img*/}
              {/*            className="img-fluid"*/}
              {/*            src={lagunaImage}*/}
              {/*            alt="Laguna Radio"*/}
              {/*          />*/}
              {/*        </a>*/}
              {/*        <MediaQuery query="(max-width: 1180px)">*/}
              {/*          <hr />*/}
              {/*        </MediaQuery>*/}
              {/*      </div>*/}
              {/*      <div className="col-lg-10 col-xl ">*/}
              {/*        <p className="text-xl-start text-center text-14 mt-2 line-height-16">*/}
              {/*          Lorem ipsum dolor sit amet, consetetur sadipscing elitr*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mt-3 p-2 me-0 ms-0 justify-content-center text-center">
              <div className="col-lg-3 col-6">
                <p className="text-16 h6-mod1 line-height-20">Delfi servis</p>
                <ul className="footer-links mt-4 text-muted jost-medium">
                  <li>
                    <Link to="/delfi-premium">Članstvo u Delfima</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/vesti">#Delfi kutak</Link>
                  </li>
                  {/*<li className="mt-2">*/}
                  {/*  <Link to="/delfi_popusti">Popusti</Link>*/}
                  {/*</li>*/}
                  <li className="mt-3">
                    <Link to="/akcije">Akcije</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/smernice_za_izdavace">Smernice za izdavače</Link>
                  </li>
                  {/*<li className="mt-2">*/}
                  {/*  <Link to="/saradnja">Saradnja</Link>*/}
                  {/*</li>*/}
                  {/*<li className="mt-2">*/}
                  {/*  <Link to="/delfi_magazin">Bukmarker</Link>*/}
                  {/*</li>*/}
                  <li className="mt-3">
                    <Link to="/booktok">BookTok</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6">
                <p className="text-16 h6-mod1 line-height-20">
                  O knjižarama Delfi
                </p>
                <ul className="footer-links mt-4 text-muted jost-medium">
                  <li>
                    <Link to="/knjizare">Knjižare</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/karijera">Karijera u Delfima</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/nasi_brendovi">Naši brendovi</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/o_nama">O nama</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-6">
                <p className="text-16 h6-mod1 line-height-20">
                  Kontakt i pomoć
                </p>
                <ul className="footer-links mt-4 text-muted jost-medium">
                  {/*<li>*/}
                  {/*  <a href="#">Dostava</a>*/}
                  {/*</li>*/}
                  <li className="mt-3">
                    <Link to="/reklamacije">Reklamacije</Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/privatnost-i-sigurnost">
                      Privatnost i sigurnost
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/uslovi_koriscenja">Uslovi korišćenja</Link>
                  </li>
                  {/*<li className="mt-2">*/}
                  {/*  <Link to="kontakt">Kontakt</Link>*/}
                  {/*  <a href="#">Kontakt</a>*/}
                  {/*</li>*/}
                  <li className="mt-3">
                    <Link to="/sta1_pomoc">Pomoć</Link>
                  </li>
                  {/*<li className="mt-2">*/}
                  {/*  <Link to="/faq">Najčešća pitanja</Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
              {/*<div className="col-lg-2 col-6 ms-xl-5 ms-md-0">*/}
              {/*  <h6 className="h6-mod1">Delfi kutak</h6>*/}
              {/*  <ul className="footer-links mt-4 text-muted text-14 jost-medium">*/}
              {/*    <li>*/}
              {/*      <Link to="vesti">Vesti</Link>*/}
              {/*    </li>*/}
              {/*    <li className="mt-2">*/}
              {/*      <a href="#">Intervjui</a>*/}
              {/*    </li>*/}
              {/*    <li className="mt-2">*/}
              {/*      <a href="#">Prikazi</a>*/}
              {/*    </li>*/}
              {/*    <li className="mt-2">*/}
              {/*      <a href="#">#knjigoljupci</a>*/}
              {/*    </li>*/}
              {/*    <li className="mt-2">*/}
              {/*      <a href="#">#TriRajke</a>*/}
              {/*    </li>*/}
              {/*    /!*<li className="mt-2">*!/*/}
              {/*    /!*  <a href="#">Video</a>*!/*/}
              {/*    /!*</li>*!/*/}
              {/*  </ul>*/}
              {/*</div>*/}
              <div className="col-lg-3 col-6">
                <p className="text-16 h6-mod1 line-height-20">Moj nalog</p>
                <ul className="footer-links mt-4 text-muted jost-medium">
                  <li>
                    <Link to="/registracija">Registracija</Link>
                  </li>
                  {/*<li className="mt-2">*/}
                  {/*  <a href="#">Odjava</a>*/}
                  {/*</li>*/}
                  <li className="mt-3">
                    <Link
                      to="/kupovina"
                      state={{
                        navigateToFirstStep: TransitionSteps.INITIAL_STATE
                      }}
                    >
                      Korpa
                    </Link>
                  </li>
                  <li className="mt-3">
                    <Link to="/lista-zelja"> Lista želja</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="row justify-content-center text-center">
              <div
                className="col-md-4 cursor-pointer"
                onClick={() =>
                  openWindowPopup(
                    'https://www.instagram.com/bukmarker.kafeterija',
                    '_blank'
                  )
                }
              >
                <div className="row align-items-center justify-content-center image-filter">
                  <div className="col-lg-5 col-6 border-end">
                    <img
                      className="img-fluid"
                      src={bukmarkerKafeterijaLogoImage}
                      alt="Bukmarker Kafeterija"
                    />
                  </div>
                  <div className="col-lg-4 col-6">
                    <span className="font-color-default">
                      Obeleži svoje mesto za kafu
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                {/*TODO promeniti na delfi_magazin cim srede stranicu*/}
                <Link to="/" className="text-decoration-none">
                  <div className="row align-items-center justify-content-center image-filter">
                    <div className="col-lg-5 col-6 border-end">
                      <img
                        className="img-fluid"
                        src={bukmarkerMagazinImage}
                        alt="Bukmarker Magazin"
                      />
                    </div>
                    <div className="col-lg-4 col-6 mt-2 mt-md-0">
                      <p className="font-color-default">
                        Magazin za sve ljubitelje knjige
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 mt-4 mt-md-0">
                <Link to="/delfi-premium" className="text-decoration-none">
                  <div className="row align-items-center justify-content-center image-filter">
                    <div className="col-lg-5 col-6 border-end">
                      <img
                        width="80"
                        height="57"
                        src={delfiPremiumImage}
                        alt="Delfi Premium"
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <span className="font-color-default">
                        Postani član PREMIUM kluba
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="row justify-content-center text-center align-items-center ms-0 me-0 gx-0">
              <div className="col-lg-1 col-4">
                <a
                  href="https://rs.visa.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={visaImage} alt="Visa Card" />
                </a>
              </div>
              <div className="col-lg-1 col-4">
                <a
                  href="https://www.mastercard.rs"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={masterCardImage} alt="Master Card" />
                </a>
              </div>
              <div className="col-lg-1 col-4">
                <a
                  href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={maestroImage} alt="Maestro Card" />
                </a>
              </div>
              <div className="col-xl-2 col-lg-3 col-6 mt-4 mt-lg-0">
                <a
                  href="https://www.bancaintesa.rs/"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={bancaIntesaImage} alt="Banka Intesa" />
                </a>
              </div>
              <div className="col-lg-1 col-3 mt-4 mt-lg-0">
                <a
                  href="https://dinacard.nbs.rs"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={dinaCardImage} alt="Dina Card" />
                </a>
              </div>
              <div className="col-lg-1 mt-4 mt-lg-0 col-3">
                <a
                  href="https://www.americanexpress.com"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={americanCardImage} alt="AmericanExpress Card" />
                </a>
              </div>
              <div className="col-lg-1 col-4 mt-4 mt-lg-0">
                <a
                  href="https://www.visa.ca/en_CA/run-your-business/merchant-resources/verified-by-visa.html"
                  target="_blank"
                  rel="noreferrer"
                  className="image-filter"
                >
                  <img src={verifiedVisaImage} alt="Verified by Visa" />
                </a>
              </div>
              <div className="col-lg-2 col-4 mt-4 mt-lg-0">
                <a target="_blank" rel="noreferrer" className="image-filter">
                  <img src={etrustImage} alt="etrust" />
                </a>
              </div>
              <div className="col-lg-1 col-4 mt-4 mt-lg-0">
                <img
                  width="120"
                  height="80"
                  src={masterCardSecureImage}
                  alt="MasterCard Secure"
                  className="image-filter"
                />
              </div>
            </div>
          </div>
          <hr />
        </section>
        <section>
          <div className="container footer-bottom-section-height">
            <div className="row mt-3 p-2 me-0 ms-0 justify-content-center text-center align-items-center">
              <div className="col-lg-8 col-md-12 text-center text-md-start border-right-delfi-info">
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <Link to="/">
                      <img
                        width="85"
                        height="40"
                        src={delfiLogoImage}
                        alt="Delfi Logo"
                      />
                    </Link>
                  </div>
                  <div className="col-md-10">
                    <span className="copyright-text text-muted">
                      Delfi knjižare d.o.o Kralja Petra 45/V, Beograd Matični
                      broj:20499753 &copy; Delfi knjižare 2024
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <ul className="social-icons float-none float-lg-end">
                  <li>
                    <a
                      className="facebook cursor-pointer"
                      onClick={() =>
                        openWindowPopup(
                          'https://www.facebook.com/DelfiKnjizare',
                          '_blank'
                        )
                      }
                    >
                      <RiFacebookLine size="1.5em" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter cursor-pointer"
                      onClick={() =>
                        openWindowPopup(
                          'https://twitter.com/delfi_knjizare',
                          '_blank'
                        )
                      }
                    >
                      <img
                        src={twitterIconImage}
                        alt="twitter-icon"
                        width="20"
                        height="20"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      className="instagram cursor-pointer"
                      onClick={() =>
                        openWindowPopup(
                          'https://www.instagram.com/delfi.knjizare/',
                          '_blank'
                        )
                      }
                    >
                      <FaInstagram size="1.5em" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="tiktok cursor-pointer"
                      onClick={() =>
                        openWindowPopup(
                          'https://www.tiktok.com/@starway_rs',
                          '_blank'
                        )
                      }
                    >
                      <FaTiktok size="1.5em" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </footer>
      {!cookieConsentData.cookieConsent && !recognizeSearchCrawler() ? (
        <section className="cookie-consent-section">
          <div className="container mt-4">
            <div className="row p-2 me-0 ms-0 justify-content-center align-items-center">
              <div className="col-md-12 font-color-default">
                <h6>Obaveštenje o kolačićima</h6>
                <p className="text-14">
                  Za vreme korišćenja bilo koje stranice na našem web-sajtu,
                  kompanija Delfi knjižare d.o.o. može da sačuva određene
                  informacije na korisnikov uređaj putem „kolačića“ (engleski
                  „cookies“).
                </p>
              </div>
              {showMoreCookieText ? (
                <div className="col-md-12 mb-3">
                  <p className="border-bottom-blue-1 text-14">
                    Kolačići omogućavaju da se sadržaj stranice prilagodi
                    potrebama korisnika, pružajući na taj način unapređenu
                    funkcionalnost i poboljšano korisničko iskustvo. Kolačići se
                    mogu koristiti i za analizu korišćenja internet stranica,
                    kao i u reklamne svrhe. Klikom na „Prihvatam“ dajete
                    saglasnost za korišćenje kolačića u svrhe navedene ispod.
                    Podešavanja kolačića možete promeniti u bilo kom trenutku.
                  </p>
                  <div className="d-flex flex-column flex-md-row gap-3">
                    <label className="opacity-50 cursor-unset">
                      <input
                        type="checkbox"
                        id="cookies-mandatory"
                        checked={true}
                        onChange={() => {}}
                        className="form-check-input"
                      />{' '}
                      Neophodni
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id="cookies-analytics"
                        defaultChecked={checkBoxValues.analytics}
                        name="analytics"
                        onChange={handleCheckboxChange}
                        className="form-check-input"
                      />{' '}
                      Analitički
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id="cookies-marketing"
                        name="marketing"
                        defaultChecked={checkBoxValues.marketing}
                        onChange={handleCheckboxChange}
                        className="form-check-input"
                      />{' '}
                      Marketing
                    </label>
                    <Link to="/privatnost-i-sigurnost" target="_blank">
                      Više o kolačićima
                    </Link>
                  </div>
                </div>
              ) : null}
              <div className="col-md-12">
                <a
                  className="navbar-brand text-16 cursor-pointer"
                  onClick={() => handleToggle()}
                >
                  <img
                    alt="ikonica"
                    src={showMoreCookieText ? upArrayImage : downArrayImage}
                    width="10"
                    height="10"
                  />
                  <span className="ms-2 font-color-default">
                    {showMoreCookieText
                      ? 'Zatvori'
                      : 'Saznaj više o kolačićima'}
                  </span>
                </a>
              </div>
            </div>
            <div className="row justify-content-center text-center justify-content-lg-end text-lg-end mb-4 mt-4 mt-lg-0">
              <div className="col-6 col-lg-4 col-xl-3">
                <button
                  className="btn custom-btn shadow-none text-14 jost-extra-bold p-2 max-height-60 col-md-10 col-sm-8 w-100"
                  type="button"
                  onClick={() => handleOnClickSetCookieConsent(false)}
                >
                  Prihvati samo neophodne
                </button>
              </div>
              <div className="col-6 col-lg-4 col-xl-3">
                <Button
                  classes="btn background-delfi shadow-none text-14 jost-extra-bold p-2 max-height-60 col-md-10 col-sm-8 text-white w-100"
                  type="button"
                  onClick={() => handleOnClickSetCookieConsent()}
                >
                  Prihvati kolačiće
                </Button>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ui: state.ui,
  newsLetterData: state.newsLetterData,
  cookieConsentData: state.cookieConsentData,
  newsletterCookieData: state.newsletterCookieData
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { subscribeNewsLetterAction, createCookieConsentAction },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
