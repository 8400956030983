import lodash from 'lodash';

const MANDATORY_KEYS = ['api.baseUrl', 'api.mediaUrl', 'baseUrl'];

const Config = {
  google_map_key: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
  api: {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    mediaUrl: process.env.REACT_APP_MEDIA_URL,
    wspayRediretDataUrl: process.env.REACT_APP_WSPAY_REDIRECT_DATA_URL
  },
  yangoOnDeliveryPdfUrl: process.env.REACT_APP_YANGO_DELIVERY_PDF_URL,
  noticeOnDataProcessingPdfUrl:
    process.env.REACT_APP_NOTICE_ON_DATA_PROCESSING_PDF_URL,
  recaptchaPublicKey: process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY || '',
  baseUrl: process.env.REACT_APP_BASE_URL,
  showNewsletterCookieModal:
    process.env.REACT_APP_SHOW_NEWSLETTER_COOKIE_MODAL || false
};

// Validate that ENV is correct

const missingKeys = lodash.reduce(
  MANDATORY_KEYS,
  (missing: string[], key): string[] => {
    const value = lodash.get(Config, key);
    if (value === undefined || value === null) {
      missing.push(key);
    }
    return missing;
  },
  []
);

if (missingKeys.length) {
  throw new Error(
    `The following values are missing from your env: ${missingKeys.join(', ')}`
  );
}

export default Config;
